import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
//import ContactForm from '../support/contactform.js';

import IframeResizer from 'iframe-resizer-react';
import  {Helmet} from "react-helmet";


export default class LegacyGetPrintJobs extends Component {


    render() {


      

        return(
            <div>
                 <Helmet>
				<title>J2 Blueprint Supply Co. |  View my previously Submitted Print Jobs</title>	
			
				<meta name="description" content="View your previously submitted print jobs" />
                </Helmet>
            <Header page_services={true}/>
            
              <div role="main" className="main">
              <div className="row">
                    
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <div class="holds-the-iframe">
                            
                            <IframeResizer 
                                heightCalculationMethod="lowestElement"
                                src={"https://uploadjob.j2b.com/getPrintJobs/"+this.props.query}
                                inPageLinks
                            
                                bodyMargin="0px"
                                style={{ width: '0px', minWidth: '100%',border:'0px'}}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>

                </div>
            <Footer />
            </div>
        );
    }

}
import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';



import  {Helmet} from "react-helmet";


export default class Homepage extends Component {

	componentDidMount() {
		setTimeout(() => {
			window.initCarousel();
			window.doCaro();
			
			console.log("Loading homepage content");
		}, 500);
	
	}

  render() {
	  return (
		  <div>
			<Helmet>
				<title>J2 Blueprint Supply Co.  |  Printing, Scanning, Banners, Signage, Artwork Reproduction and Machine Sales &amp; Service</title>	
				<meta name="keywords" content="Print, print shop, commercial printer, scan, scanner, printer, copier, color, giclee, hp, canon, oce, large format, large, wide, ink, toner, paper" />
				<meta name="description" content="J2 is a full service print shop in Vancouver, WA, specializing in large format reprographics and machine sales and service." />
            </Helmet>

		  <Header page_home={true}/>
		

		  
	
		  			<div className="container">
					
		  
				<section className="page-header page-header-modern page-header-background page-header-background-sm overlay overlay-color-primary overlay-show overlay-op-8 mb-5" style={{backgroundImage: "url(j2img/pacific_northwest_ashford.jpg)"}}>

					<div className="container">
					
					<div className="row">
						<div className="col-lg-12">
							&nbsp;
						</div>
					</div>
						<div className="row">

							<div className="col-md-12 align-self-center p-static order-2 text-center">

								<h1>Print Services, Equipment and Supplies in the Pacific Northwest.</h1>

							</div>

						</div>

					</div>

				</section>

			




		  
			<div role="main" className="main">

				
				
					<div className="row">
					

						<div className="col-lg-4 pl-lg-0 pr-lg-2 mb-3 appear-animation" data-appear-animation="fadeInRightShorter">

							<span className="thumb-info thumb-info-no-borders thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">

								<span className="thumb-info-wrapper overlay overlay-show overlay-op-2">

									<img src="j2img/pagewide/hppagewide.png" className="img-fluid" alt=""/>

									<span className="thumb-info-title  mb-4 pl-4 ">

										<span className="thumb-info-type font-weight-light text-1 line-height-1 opacity-8">HP PageWide Small Format</span>

										<span className="thumb-info-inner font-weight-bold text-4 line-height-4">PageWide MFPs</span>

										<span className="thumb-info-show-more-content pr-3">

											<p className="mb-0 text-1 line-height-9 pr-5 mr-5 mb-3 mt-2">A new era of low-cost color, high productivity, and strongest security.</p>

											<a href="/equipment/mfp" className="d-inline-flex align-items-center btn btn-light text-color-dark font-weight-bold px-2 btn-py-1 text-1 rounded"><span className="text-2 py-1 pl-2">LEARN MORE</span> <i className="fa fa-arrow-right ml-2 mr-2 pl-4 text-3"></i></a>

										</span>

									</span>

								</span>

							</span>

						</div>

						<div className="col-lg-4 px-lg-1 mb-3 appear-animation" data-appear-animation="fadeInDownShorter" data-appear-animation-delay="600">

							<span className="thumb-info thumb-info-no-borders thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">

								<span className="thumb-info-wrapper overlay overlay-show overlay-op-2">

									<img src="j2img/pagewide/PageWideXLFamily_Image_opt.jpg" className="img-fluid" alt=""/>

									<span className="thumb-info-title  mb-4 pl-4 ">

										<span className="thumb-info-type font-weight-light text-1 line-height-1 opacity-8">Fast, high quality technical document production</span>

										<span className="thumb-info-inner font-weight-bold text-4 line-height-4">HP PageWide XL Series</span>

										<span className="thumb-info-show-more-content pr-3">

											<p className="mb-0 text-1 line-height-9 pr-5 mr-5 mb-3 mt-2">Powerful, efficient monochrome and color production</p>

											<a href="/equipment/wide" className="d-inline-flex align-items-center btn btn-light text-color-dark font-weight-bold px-2 btn-py-1 text-1 rounded"><span className="text-2 py-1 pl-2">LEARN MORE</span> <i className="fa fa-arrow-right ml-2 mr-2 pl-4 text-3"></i></a>

										</span>

									</span>

								</span>

							</span>

						</div>

						<div className="col-lg-4 pr-lg-0 pl-lg-2 mb-3 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">

							<span className="thumb-info thumb-info-no-borders thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">

								<span className="thumb-info-wrapper overlay overlay-show overlay-op-2">

									<img src="img/signageimage_opt.jpg" className="img-fluid" alt=""/>

									<span className="thumb-info-title  mb-4 pl-4 ">

										<span className="thumb-info-type font-weight-light text-1 line-height-1 opacity-8">Banners, POP's and Temporary Signage</span>

										<span className="thumb-info-inner font-weight-bold text-4 line-height-4">Custom Signage</span>

										<span className="thumb-info-show-more-content pr-3">

											<p className="mb-0 text-1 line-height-9 pr-5 mr-5 mb-3 mt-2">J2 offers the perfect versatile, reliable, and cost-effective signage printing to take your presentation to the next level.</p>

											<a href="/services/print/signs" className="d-inline-flex align-items-center btn btn-light text-color-dark font-weight-bold px-2 btn-py-1 text-1 rounded"><span className="text-2 py-1 pl-2">LEARN MORE</span> <i className="fa fa-arrow-right ml-2 mr-2 pl-4 text-3"></i></a>

										</span>

									</span>

								</span>

							</span>

						</div>

					</div>


					

				</div>


				<section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Design <strong>custom posters</strong> online.</h3>
							<p className="mb-0 opacity-7">Our <strong className="font-weight-extra-bold">Online Design Tool</strong> will help you create eye popping posters right in your browser.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/design" target="_blank" className="btn btn-modern text-2 btn-light border-0">Get Started Now!</a>
						</div>
					</div>
				</section>
			
				<section className="section section-no-border  text-center mt-0" style={{backgroundColor:"rgb(154, 1, 7)"}}>

					<div className="container">

							<div className="row">
								<div className="col-lg-2">
								</div>
								<div className="col-lg-8">
									<div className="" style={{backgroundColor:"rgb(154,1,7)"}}>
									<div className="row">
										<div className="col-lg-2"></div>
										<div className="col-lg-8"><h2 className="text-color-light">Custom Corona Virus Signage</h2></div>
										<div className="col-lg-2"></div>
										
									</div>
									<div className="row">
											<div className="col-lg-8">
											<a href="/covid"><img src="/img/covidbanner_home.jpg" alt="Covid Banners" width="100%"/></a>

											</div>
											<div className="col-lg-4 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">
												<br/><br/>
												<h3 className="text-color-light">We can print your custom signs or you can choose from our premade solutions.</h3>
												<center><a href="/covid" className="btn btn-modern btn-primary mt-1">Show me more!</a></center>

											</div>
										</div>
									</div>											
								</div>
								<div className="col-lg-2">							
								</div>
							</div>

					</div>

					</section>




				

				<section className="section section-primary section-no-border my-0">

					<div className="container mb-5 pb-5">

						<div className="row justify-content-center">

							<div className="col">

								<div className="row pt-4">

									<div className="col-md-8 order-2 order-md-1 appear-animation" data-appear-animation="fadeInRightShorter">

										<div className="row text-center text-md-left mb-5 pb-5">

											<div className="col-lg-7">

												<h2 className="text-7 font-weight-semibold mb-2">Printing is our business.</h2>

											</div>

											<div className="col-lg-10">

												<p className="text-3 line-height-9 opacity-7">We can provide quick turn around print services for you in our high volume production facility or supply you with the machines and support you need to do the work on site.</p>

												<a href="/services" className="d-inline-flex align-items-center btn btn-dark text-color-light font-weight-bold px-4 btn-py-2 text-2 rounded">VIEW OUR SERVICES <i className="fa fa-arrow-right ml-2 pl-1 text-3"></i></a>

											</div>

										</div>

									</div>

									<div className="col-md-4 text-center order-1 order-md-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">

										<p className="text-1 mb-2 opacity-7">J2 Provides everything you need to produce quality prints large and small. Our factory certified technicians can keep your operation running smooth.</p>

										

									</div>

								</div>

							</div>

						</div>

					</div>



				</section>



				<div className="container">

					<div className="row" style={{marginTop: '-165px'}}>

						<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">

							

							<div className="card flip-card flip-card-3d bg-transparent text-center rounded-0">

								<div className="flip-front p-5">

									<div className="flip-content my-4">

										<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">Buy</strong>

										<h4 className="font-weight-bold text-color-primary text-4">Buy a Printer</h4>

										<p>It's your printer. We'll keep you printing at a competative rate</p>	

									</div>

								</div>

								<div className="flip-back d-flex align-items-center p-5" style={{backgroundImage: 'url(j2img/123_opt.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

									<div className="flip-content my-4">

										<h4 className="font-weight-bold text-color-light">Purchase</h4>

										<p className="font-weight-light text-color-light opacity-5">With a wide selection of equipment for you to choose from we'll make sure you get what you need</p>

										<a href="/equipment" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>

									</div>

								</div>

							</div>

						</div>


						<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">

							

						<div className="card flip-card flip-card-3d bg-transparent text-center rounded-0">

							<div className="flip-front p-5">

								<div className="flip-content my-4">

									<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">Refill</strong>

									<h4 className="font-weight-bold text-color-primary text-4">Buy Supplies</h4>

									<p>Buy Supplies for your plotter or printer. OEM Inks and Toners, Paper and more.</p>	

								</div>

							</div>

							<div className="flip-back d-flex align-items-center p-5" style={{backgroundImage: 'url(j2img/123_opt.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

								<div className="flip-content my-4">

									<h4 className="font-weight-bold text-color-light">Purchase</h4>

									<p className="font-weight-light text-color-light opacity-5">With a wide selection of equipment for you to choose from we'll make sure you get what you need</p>

									<a href="/supplies" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>

								</div>

							</div>

						</div>

						</div>




						<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">

							

							<div className="card flip-card flip-card-3d bg-transparent text-center rounded-0">

								<div className="flip-front p-5">

									<div className="flip-content my-4">

										<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">Lease</strong>

										<h4 className="font-weight-bold text-color-primary text-4">Lease a Printer</h4>

										<p>We'll take care of everything for one low monthly fee</p>	

									</div>

								</div>

								<div className="flip-back d-flex align-items-center p-5" style={{backgroundImage: 'url(j2img/123.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

									<div className="flip-content my-4">

										<h4 className="font-weight-bold text-color-light">Leasing</h4>

										<p className="font-weight-light text-color-light opacity-5">You'll never have to worry about supplies or repairs, we'll take care of everything.</p>

										<a href="/lease" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>

									</div>

								</div>

							</div>

						</div>



						<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">

							

							<div className="card flip-card flip-card-3d bg-transparent text-center rounded-0">

								<div className="flip-front p-5">

									<div className="flip-content my-4">

										<strong className="font-weight-extra-bold text-color-dark line-height-1 text-13 mb-3 d-inline-block">Print</strong>

										<h4 className="font-weight-bold text-color-primary text-4">We'll Print for you</h4>

										<p>Submit your work electronically and we'll handle the printing for you</p>	

									</div>

								</div>

								<div className="flip-back d-flex align-items-center p-5" style={{backgroundImage: 'url(j2img/123_opt.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

									<div className="flip-content my-4">

										<h4 className="font-weight-bold text-color-light">Print</h4>

										<p className="font-weight-light text-color-light opacity-5">You submit your files, we'll handle the rest.</p>

										<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-light btn-modern text-color-dark font-weight-bold">LEARN MORE</a>

									</div>

								</div>

							</div>

						</div>

					</div>

					<div className="row text-center text-md-left align-items-center mt-4 appear-animation mt-5" data-appear-animation="fadeInRightShorter">

						<div className="col-md-7 col-lg-7">

							<div className="row">

								<div className="col-lg-9">

									<h2 className="font-weight-bold text-7 mb-3">About J2</h2>

									<p className="text-4">We've been proudly serving the Vancouver, WA and Portland Metro areas for over 25 years.</p>

								</div>

							</div>

							<div className="row">

								<div className="col-lg-7">

									<p className="text-2 pr-lg-2">As a proud member of our community J2 has been providing print services for more than 25 years! </p>

									<a href="/services" className="d-inline-flex align-items-center btn btn-dark text-color-light font-weight-bold px-4 btn-py-2 text-2 rounded mt-2 mb-5">LEARN MORE NOW <i className="fa fa-arrow-right ml-2 pl-1 text-3"></i></a>

								</div>

							</div>

						</div>

						<div className="col-md-5 col-lg-4 px-5 px-md-3">

							<img className="img-fluid" src="j2img/plans_opt.jpg" alt="" />

						</div>

					</div>

				</div>



				<section className="section section-no-border section- section-height-3 bg-color-grey-scale-3 text-center mt-0">

					<div className="container">

						<div className="row">

							<div className="col-md-9 col-lg-9">

								<div className="call-to-action-content">

									<h2 className="font-weight-normal text-6 mb-0">J2 has <strong className="font-weight-extra-bold">everything</strong> you need to print <strong className="font-weight-extra-bold">awesome</strong> projects!</h2>

									<p className="mb-0">The best Print Shop in the Pacific Northwest.</p>

								</div>

							</div>

							<div className="col-md-3 col-lg-3">

								<div className="call-to-action-btn">

									<a className="btn btn-badge btn-primary font-weight-bold px-4 mt-4 mt-md-2 mb-2" target="_new" href="https://g.page/j2blue?share">Visit Now!<span className="badge badge-dark badge-sm badge-pill text-uppercase px-2 py-1"></span></a>

								</div>

							</div>

						</div>

					</div>

				</section>



				<div className="container">



					<div className="row pt-4">

						<div className="col-lg-8 pb-2">



							<div className="process process-vertical pt-4">

								<div className="process-step appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">

									<div className="process-step-circle">

										<strong className="process-step-circle-content">1</strong>

									</div>

									<div className="process-step-content">

										<h4 className="mb-1 text-4 font-weight-bold">Upload your Files</h4>

										<p className="mb-0">Upload your files via our electronic document submission tools </p>

									</div>

								</div>

								<div className="process-step appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">

									<div className="process-step-circle">

										<strong className="process-step-circle-content">2</strong>

									</div>

									<div className="process-step-content">

										<h4 className="mb-1 text-4 font-weight-bold">Check your proofs</h4>

										<p className="mb-0">If requested, we can provide proofs so you can verify your output.</p>

									</div>

								</div>

								<div className="process-step appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">

									<div className="process-step-circle">

										<strong className="process-step-circle-content">3</strong>

									</div>

									<div className="process-step-content">

										<h4 className="mb-1 text-4 font-weight-bold">Get your prints</h4>

										<p className="mb-0">You can pick them up, We can deliver locally, or ship anywhere in the world!</p>

									</div>

								</div>

								<div className="process-step pb-4 mb-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">

									<div className="process-step-circle">

										<strong className="process-step-circle-content">4</strong>

									</div>

									<div className="process-step-content">

										<h4 className="mb-1 text-4 font-weight-bold">Order again!</h4>

										<p className="mb-0">You'll love the quality of your prints, so order up another set!</p>

									</div>

								</div>

							</div>



						</div>

						<div className="col-lg-4 px-5 pt-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">

							<div className="testimonial testimonial-style-7 testimonial-primary py-5 mt-4">

								<blockquote>

									<p className="text-6 line-height-9 mb-0 mt-4">Your time is limited, so don’t waste it dealing with less experienced print shops.</p>

								</blockquote>

								<a className="btn btn-outline btn-light text-light font-weight-bold mb-3" href="/services">LEARN MORE</a>

							</div>

						</div>



					</div>
					<hr/>
					<div className="row">
						<div className="col-lg-12">
							<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1, autoWidth:true}, '476': {'items': 1, autoWidth:true}, '768': {'items': 2, autoWidth:true}, '992': {'items': 2, autoWidth:true}, '1200': {'items': 2, autoWidth:true}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">
							
								<div>
									<img className="img-fluid" src="/j2img/brandscroller/ImageAccess_opt.png" height="75px" alt=""/>
								</div>
								<div>
									<a href="https://www.j2b.com/documents/StateofWashingtonNVPCopierMPSExecutedHPPA.pdf"><img className="img-fluid" src="/j2img/brandscroller/naspo_opt.png" height="75px"  alt="NASPO"/></a>
								</div>
								<div>
									<img className="img-fluid" src="/j2img/brandscroller/RMXNetwork_opt.png" height="75px"  alt="RMXNetwork"/>
								</div>
							
								<div>
									<img className="img-fluid" src="/img/logos/supplies/hp_opt.png"  alt="HP"/>
								</div>
							{/*	<div>
									<img className="img-fluid" src="/img/logos/supplies/canon_opt.png"  alt="Canon"/>
							</div> */}
								<div>
									<img className="img-fluid" src="/img/logos/supplies/kyocera.png"  alt="Kyocera"/>
								</div>
								<div>
									<img className="img-fluid" src="/img/logos/supplies/oce_opt.png"   alt="OCE"/>
								</div>
								<div>
									<img className="img-fluid" src="/img/logos/supplies/rb_opt.png"   alt="RB"/>
								</div>
								
							</div>					
						</div>
						
					</div>

				</div>

				<div className="container">
								
						
								<div className="row">
									<div className="col-lg-2">
									</div>
									<div className="col-lg-8">
									
										<center><img src="/j2img/banners/essential_opt.jpg" alt="Essential Business" width="100%"/></center>							
									</div>
									<div className="col-lg-2">							
									</div>
								</div>
							</div>


		  
			</div>
		  <Footer/>
		  </div>
	  
	  )
  }
}
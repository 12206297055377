import React, { Component } from 'react';

import '../../public/admin/admin/theme.css';
import '../../public//vendor/bootstrap/css/bootstrap.min.css';
//import '../../public/vendor/fontawesome-free/css/all.min.css';
import '../../public//vendor/animate/animate.min.css';
//import '../../public//vendor/simple-line-icons/css/simple-line-icons.min.css';
import '../../public//vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../public//vendor/owl.carousel/assets/owl.theme.default.min.css';
//import '../../public//vendor/magnific-popup/magnific-popup.min.css';
import '../../public/css/theme.css';
import '../../public/css/theme-elements.css';


//** */
import '../../public/css/theme-blog.css';
import '../../public//css/theme-shop.css';
//** */



//import '../../public/vendor/rs-plugin/css/settings.css';
//import '../../public/vendor/rs-plugin/css/layers.css';
//import '../../public/vendor/rs-plugin/css/navigation.css';
import '../../public/css/skins/skin-corporate-17.css';

//** */
import '../../public/admin/admin/skins/default.css';
//** */

import '../../public/css/custom.css';


import { FaAngleRight,FaAngleDown,FaPhone,FaClock,FaRocket,FaWrench,FaPrint,FaFacebookF,FaLinkedinIn,FaBars } from 'react-icons/fa';
 

export default class Header extends Component {
	
	constructor(props)
	{
		super(props);

		var fhead = false;
		if (props.fixhedHeader !== undefined) {
			fhead = props.fixedHeader;
		} else {
			fhead = true;
		}
		this.state = {fixedHeader:fhead}
		//this.setState({fixedHeader:fhead});
	}
		componentDidMount() {
			window.initStickyHeader();
			//window.initCarousel();
			window.theme.StickyHeader.initialize();
			window.initNav();
			window.theme.Nav.initialize();
			window.initPlugins();

			
			
		}

  render() {
	  //var fhead = this.state.fixedHeader;
	  return (
		<div>
			
			<header id="header" className="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 110}" style={{height:"175px"}}>
			
				<div className="header-body border-top-0">

					<div className="header-top">

						<div className="container">

							<div className="header-row py-2">

								<div className="header-column justify-content-start">

									<div className="header-row">

										<nav className="header-nav-top">
											<ul className="nav nav-pills">
											{process.env.REACT_APP_SITE_CO === "j2b" && 
											<>
												<li className="nav-item nav-item-anim-icon d-none d-lg-block">
													<a className="nav-link" href="/bid/distribution"><FaAngleRight className="fa-selector"/> Bidsets</a>
												</li>
												<li className="nav-item nav-item-anim-icon d-none d-lg-block">
													<a className="nav-link" target="_new" href="https://plans.j2b.com/"><FaAngleRight className="fa-selector"/> Plancenter</a>
												</li>
											</>
											}
												<li className="nav-item nav-item-anim-icon d-none d-md-block">
													<a className="nav-link" target="_new" href="https://einfo.j2b.com/"><FaAngleRight className="fa-selector"/> My Account</a>
												</li>
												{process.env.REACT_APP_SITE_CO ==="j2bsys" &&
												<li className="nav-item nav-item-anim-icon d-none d-md-block">
												
													<a className="nav-link"  href="/repair"><FaAngleRight className="fa-selector"/> Repair</a>
											
												</li>
												}

												{process.env.REACT_APP_SITE_CO === "j2b" && 
												<>
												<li className="nav-item dropdown nav-item-left-border d-none d-sm-block nav-item-left-border-remove nav-item-left-border-md-show">
													<span className="nav-link"  role="button" id="dropdownForms" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<FaClock/> Quick Requests
														<FaAngleDown className="fa-selector"/>
													</span>
													<div className="dropdown-menu" aria-labelledby="dropdownForms" >
														<a className="dropdown-item" href="/pickup"><FaRocket className="fa-selector"/>  Pickup Request</a>
														<a className="dropdown-item" href="/quicksupply"><FaPrint className="fa-selector"/> Quick Supply Order</a>
														<a className="dropdown-item" href="/repair/servicecall"><FaWrench className="fa-selector"/> Service Calls</a>
														
													</div>
												</li>
 												 
												</>
												}
												<li className="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-md-show">
												{process.env.REACT_APP_SITE_CO === "j2b" && 
												<>
												<a className="nav-link" target="_new"  href="https://uploadjob.j2b.com/upload/"><FaAngleRight className="fa-selector"/> <span className="" style={{color:"red"}}>Upload a Job</span></a>
												</>
												}
												

													<span className="ws-nowrap"><FaPhone className="fa-selector"/> 1-800-464-0069</span>
												</li>
												
											</ul>
										</nav>

									</div>

								</div>

								<div className="header-column justify-content-end">

									<div className="header-row">

										<ul className="header-social-icons social-icons d-none d-sm-block social-icons-clean">

											<li className="social-icons-facebook"><a href="https://www.facebook.com/J2-Blueprint-Supply-co-113396852031089/" target="_blank" rel="noopener noreferrer" title="Facebook"><FaFacebookF className="fa-selector"/></a></li>

										
											<li className="social-icons-linkedin"><a href="https://www.linkedin.com/company/j2-blueprint-supply-co/" target="_blank" rel="noopener noreferrer" title="Linkedin"><FaLinkedinIn className="fa-selector"/></a></li>

										</ul>

									</div>

								</div>

							</div>

						</div>

					</div>

					<div className="header-container container">

						<div className="header-row">

							<div className="header-column">

								<div className="header-row">

									<div className="header-logo">

										<a href="/">
										{process.env.REACT_APP_SITE_CO === "j2b" && 
											
											<img alt="J2 Blueprint" width="200" height="64" data-sticky-width="100" data-sticky-height="32" src="/img/j2logo_opt.png"/>
										}
										{process.env.REACT_APP_SITE_CO === "j2bsys" && 
											<img alt="J2 Business Systems" width="254" height="64" data-sticky-width="128" data-sticky-height="32" src="/img/j2bsys.png"/>
										}
										</a>

									</div>

								</div>

							</div>

							<div className="header-column justify-content-end">

								<div className="header-row">

									<div className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">

										<div className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">

											<nav className="collapse">

												<ul className="nav nav-pills" id="mainNav">

													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_home ? 'active' : '')} href="/">
															Home
														</a>
														
													</li>

													{process.env.REACT_APP_SITE_CO === "j2b" && 
													<>
													<li className="dropdown dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_equipment?'active' : '')} href="/equipment">
															Equipment
														</a>
														
														<ul className="dropdown-menu">
															<li><a className="dropdown-item" href="/equipment/mfp">HP Business Printers</a></li>
															<li><a className="dropdown-item" href="/equipment/wide">Wide Format MFP Printers</a></li>
															<li><a className="dropdown-item" href="/equipment/cad">Wide Format CAD Printers</a></li>
															<li><a className="dropdown-item" href="/equipment/graphics">Wide Format Graphics Printers</a></li>
															<li><a className="dropdown-item" href="/equipment/scanners">Scanners</a></li>
															<li><a className="dropdown-item" href="/lease">Equipment Leasing</a></li>
														</ul>
															
														
													</li>
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_supplies?'active' : '')} href="/supplies">
															Supplies
														</a>
														
													</li>
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_services?'active' : '')} href="/services">
															Services
														</a>
														
														<ul className="dropdown-menu">
														
															<li className="dropdown-submenu">
																<a className="dropdown-item" href="/services">Print Services</a>
																<ul className="dropdown-menu">
																	<li><a className="dropdown-item" href="/services/print/engineering">Engineering / Construction</a></li>
																	<li><a className="dropdown-item" href="/services/print/business">Business Documents</a></li>
																	<li><a className="dropdown-item" href="/services/print/fineart">Fine Arts</a></li>
																	<li><a className="dropdown-item" href="/services/print/signs">Signs &amp; Banners</a></li>
																	<li><a className="dropdown-item" href="/services/print/smallformat">Cards, Flyers &amp; Brochures</a></li>
																	<li><a className="dropdown-item" href="/services/print/lamination">Lamination &amp; Mounting</a></li>
																	<li><a className="dropdown-item" href="/filesize-dpi">File &amp; Print Sizes / DPI</a></li>
																	
																</ul>
															</li>
															<li className="dropdown-submenu">
																<a className="dropdown-item" href="/services/scans">Scanning Services</a>
																<ul className="dropdown-menu">
																	<li><a className="dropdown-item" href="/services/scans">Document Archival</a></li>
																	<li><a className="dropdown-item" href="/services/scans/art">Artwork Scanning</a></li>
																	
																</ul>
															</li>
															<li className="dropdown-submenu">
																<a className="dropdown-item" href="/services/bid/distribution">Bid Services</a>
																<ul className="dropdown-menu">
																	<li><a className="dropdown-item" href="/bid/distribution">Current Bidsets</a></li>
																	<li><a className="dropdown-item" href="https://plans.j2b.com/">Online Planrooms</a></li>
																	<li><a className="dropdown-item" href="/services/bid/plancenter">Private Plan Room Service</a></li>
																	<li><a className="dropdown-item" href="/services/bid/distribution">Bidset Distribution</a></li>
																</ul>
															</li>
															
															<li><a className="dropdown-item" target="_new" href="https://uploadjob.j2b.com/upload/">Upload a Job</a></li>
															<li><a className="dropdown-item" href="/design">Design Tools / Services</a></li>
															<li><a className="dropdown-item" target="_new" href="https://einfo.j2b.com/">My Account Login</a></li>
															
														</ul>
													</li>
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_repair?'active' : '')} href="/repair">
															Equipment Repair
														</a>
														<ul className="dropdown-menu">
															<li><a className="dropdown-item" target="_new" href="https://secure.logmeinrescue.com/Customer/Code.aspx">Join Remote Service Session</a></li>
															<li><a className="dropdown-item" href="/repair/servicecall">Place a Service Call</a></li>
														
														</ul>
													</li>
													
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_support?'active' : '')} href="/support">
															Help
														</a>
														
													</li>
													</>
 													 }
													{process.env.REACT_APP_SITE_CO === "j2bsys" && 
													<>
<li className="dropdown dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_equipment?'active' : '')} href="/equipment">
															Office Equipment
														</a>
														
														<ul className="dropdown-menu">
															<li><a className="dropdown-item" href="/hp/mps">Copiers</a></li>
															<li><a className="dropdown-item" href="/hp/printersandscanners">Printers and Scanners</a></li>
															<li><a className="dropdown-item" href="/equipment">Wide Format</a></li>
															<li><a className="dropdown-item" href="/hp/mps">Managed Print Systems</a></li>
															<li><a className="dropdown-item" href="/lease">Equipment Leasing</a></li>
															<li><a className="dropdown-item" href="https://www.j2b.com/services">Prining Services</a></li>
														</ul>
															
														
													</li>
													{process.env.REACT_APP_SITE_CO === "j2b" && 
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_einfo?'active' : '')} target="_new" href="https://einfo.j2b.com/">
															My Account
														</a>
														
													</li>
  													}
													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_repair?'active' : '')} href="/repair">
															Equipment Repair
														</a>
														<ul className="dropdown-menu">
															<li><a className="dropdown-item" target="_new" href="https://secure.logmeinrescue.com/Customer/Code.aspx">Join Remote Service Session</a></li>
															<li><a className="dropdown-item" href="/repair/servicecall">Place a Service Call</a></li>
														
														</ul>
													</li>

													<li className="dropdown">
														<a className={"dropdown-item dropdown-toggle "+ (this.props.page_support?'active' : '')} href="/support">
															Support
														</a>
														
													</li>
													{process.env.REACT_APP_SITE_CO === "j2bsys" && 
													<>
													
													<li className="dropdown">
													<a className={"dropdown-item dropdown-toggle "} href="https://www.j2b.com">
														Print Shop
													</a>
													</li>
													<li className="dropdown">
													<a className={"dropdown-item dropdown-toggle "+ (this.props.page_about?'active' : '')} href="/about">
														About Us
													</a>
													</li>

													</>
  													}

													</>
													}

												</ul>

											</nav>

										</div>

										<button className="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">

											<FaBars className="fa-selector"/>

										</button>

									</div>

									<div className="header-nav-features header-nav-features-no-border header-nav-features-lg-show-border order-1 order-lg-2">
										
									
									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</header>
		</div>
	  )
  }
}